
import { computed, defineComponent, ref } from 'vue'
import {
	Setting,
	Document,
} from '@element-plus/icons'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/pinia/user/user.pinia'
import { useWindowSize } from '@/use/useWindowSize'

export default defineComponent({
	name: 'LayoutMentReply',
	components: {},
	setup() {
		const userStore = useUserStore()
		const route = useRoute()

		const userPermissionShop = computed(
			() => userStore.getSettingShopPermission
		)
		const { mobileMode: isMobile } = useWindowSize()
		const menuList = [
			{
				path: `/page-comment/${route.params.pageId}`,
				icon: Document,
				title: 'ข้อความตอบกลับ',
				isShow: true,
			},
			{
				path: `/page-cf/${route.params.pageId}`,
				icon: Setting,
				title: 'CF สินค้าใต้โพสต์',
				isShow: true,
			}
		]
		const menuFilterIsShow = computed(() =>
			menuList.filter((menu) => {
				if (isMobile.value) {
					return (
						menu.isShow &&
						menu.path !== '/shop/detail/payment-receipt'
					)
				}
				return menu.isShow
			})
		)

		return {
			userPermissionShop,
			menuList,
			menuFilterIsShow,
		}
	},
})
