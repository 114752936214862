import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02511d85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-wrapper" }
const _hoisted_2 = { class: "menu" }
const _hoisted_3 = { class: "menu__list" }
const _hoisted_4 = { class: "menu-item--icon flex align-center" }
const _hoisted_5 = { class: "menu-item--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, {
      class: "pt-8 container-wrapper__inner",
      gutter: 20
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          md: 24,
          lg: 5,
          xl: 4
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuFilterIsShow, (menu, i) => {
                  return (_openBlock(), _createBlock(_component_RouterLink, {
                    key: `menu-${i}`,
                    to: menu.path,
                    class: _normalizeClass(["menu-item", { active: _ctx.$route.path === menu.path }])
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_el_icon, {
                          size: 20,
                          color: "#FFFFFF"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(menu.icon)))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(menu.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "class"]))
                }), 128))
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          class: "control-top",
          md: 24,
          lg: 19,
          xl: 20
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_card, {
              shadow: "never",
              class: "h-full"
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}